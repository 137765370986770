import React, { useState,useEffect } from "react";
import PremiumSport from "./PremiumSport";
import BookFancy from "./BookFancy";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import {Table } from "react-bootstrap";
import helpers from "../../utils/helpers";

const FancySport = ({ data, active, premiumFancy, handelPremiumFancy,eventId }) => {
  const [tab, setTab] = useState("fancy");
  const [bookPositionData, setBookPositionData] = useState({});
  const [declearedfancy, setDeclearedfancy] = useState({});


 const getData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.eventSessionBets + "?eventId="+eventId+"&isDeclared="+true
    );
    if (status === 200) {
      if (response_users.success) {
       console.log("response_users.results",response_users.results);
       setDeclearedfancy(response_users.results);
       
      }
    }
  };

  useEffect(() => {
    if(tab=="declearedFancy"){
      getData();
    }
   
  }, [tab=="declearedFancy"]); 

  return (
    <div class="row mt-3">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="main-analysis mb-2">
          <div className="d-flex fancybtn-new-outer">
            <div
              onClick={() => setTab("fancy")}
              className={`fancybtn-new ${tab == "fancy" ? "active" : ""}`}
              style={{ marginRight: "5px" }}
            >
              <a>Fancy </a>
            </div>
            <div
              onClick={() => setTab("premiumFancy")}
              className={`fancybtn-new ${
                tab == "premiumFancy" ? "active" : ""
              }`}
              style={{ marginRight: "5px" }}
            >
              <a>Premium Fancy</a>
            </div>
            <div
              onClick={() => setTab("declearedFancy")}
              className={`fancybtn-new ${
                tab == "declearedFancy" ? "active" : ""
              }`}
            >
              <a>Decleared Fancy</a>
            </div>
          </div>

          {/* <div class="w-100 d-block fancybg">
            <ul class="fancy-filter">
              <li>
                <a class="">All</a>
              </li>
            </ul>
            <span
              class="fancyplus-icon"
              style={{ paddingLeft: "5px", cursor: "pointer" }}
            >
              {active?.fancy ? (
                <i
                  onClick={() =>
                    setActive((prev) => {
                      return {
                        ...prev,
                        fancy: !prev.fancy,
                      };
                    })
                  }
                  class="fa fa-minus"
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  onClick={() =>
                    setActive((prev) => {
                      return {
                        ...prev,
                        fancy: !prev.fancy,
                      };
                    })
                  }
                  class="fa fa-plus"
                  aria-hidden="true"
                ></i>
              )}
            </span>
          </div> */}

          {active?.fancy && tab == "fancy" && data?.length > 0 ? (
            <div class="table-responsive  game-wrap">
              <table class="w-100 analysis-running-market bookmakerfancy fancytable">
                <tbody>
                  <tr class="bet-all-new">
                    <td class="fancyw-65 pb-0">&nbsp;</td>
                    <td class="fancyw-20 pb-0 text-center">
                      <div>
                        <div class="fantitle d-inline">
                          <a id="btnBack" class="btn-back bg-trans" side="Back">
                            <span class="ask-price">No</span>
                          </a>
                        </div>
                        <div class="fantitle d-inline">
                          <a id="btnLay" class="btn-lay bg-trans" side="Lay">
                            <span class="ask-price">Yes</span>
                          </a>
                        </div>
                      </div>
                    </td>
                    <td class="fancyw-15 pb-0 p-relative mobile-hide">
                      &nbsp;
                    </td>
                  </tr>
                  {data.length>0 && data?.map((res) => {
                    return (
                      <tr>
                        <td class="fancyw-50 px-1">
                          <div class="runningf in-play-title ">
                            <div class="d-block">
                              <span class="marketnamemobile">
                                {res?.fancyName}
                              </span>
                            </div>
                            <div className="d-flex">
                              {Math.abs(res?.position) > 0 && (
                                <div
                                  style={{
                                    fontWeight: "600",
                                    marginRight: "5px",
                                  }}
                                  class={
                                    res?.position <= 0
                                      ? "text-danger d-flex align-items-center"
                                      : "text-success  d-flex align-items-center"
                                  }
                                >
                                  {parseFloat(Math.abs(res?.position)).toFixed(
                                    2
                                  )}
                                </div>
                              )}{" "}
                              {(res?.position != "" || res?.position === 0) &&
                                !isNaN(Math.abs(res?.position)) && (
                                  <a
                                    id="fancyBetBookBtn"
                                    href="javascript:void(0)"
                                    className="btn-book"
                                    onClick={() => {
                                      setBookPositionData({
                                        status: true,
                                        item: res,
                                      });
                                    }}
                                  >
                                    Book
                                  </a>
                                )}
                            </div>
                          </div>
                        </td>
                        <td class="w-16">
                          <div
                            class={
                              res?.odds?.ms == 9 ||
                              res?.odds?.ms == 2 ||
                              res?.odds?.ms == 3 ||
                              res?.odds?.ms == 4
                                ? "ballrunning-fancy"
                                : ""
                            }
                            datascustomattribute={
                              res?.odds?.ms == 9
                                ? "Ball Running"
                                : res?.odds?.ms == 2
                                ? "In Active"
                                : res?.odds?.ms == 3
                                ? "Suspended"
                                : res?.odds?.ms == 4
                                ? "Closed"
                                : ""
                            }
                          >
                            {/* class="ballrunning-fancy" */}
                            {/* condition for ball running */}
                            <div class=" ask d-inline">
                              <span class="ask-price">
                                {res?.rt?.length > 0 ? res?.rt[0]?.rt : "-"}
                              </span>
                              <span class="ask-price-small">
                                {res?.rt?.length > 0 ? res?.rt[0]?.pt : "-"}
                              </span>
                            </div>
                            <div class="bid d-inline">
                              <span class="bid-price">
                                {res?.rt?.length > 0 ? res?.rt[1]?.rt : "-"}
                              </span>
                              <span class="bid-price-small">
                                {res?.rt?.length > 0 ? res?.rt[1]?.pt : "-"}
                              </span>
                            </div>
                          </div>
                        </td>
                        {/* <td class="w-16 w-16-1 mobile-hide">
                          <div class="min-max-price">
                            <span class="d-block">
                              Min: <span>{minMax?.minAmount || 100}</span>
                            </span>
                            <span class="d-block ">
                              Max: <span>{minMax?.maxAmount || 100000}</span>
                            </span>
                          </div>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : tab == "fancy" && (
            <div className="w-100 bg-white text-black p-2 ">
              No Record Found
            </div>
          )}
          {tab == "premiumFancy" && premiumFancy?.length > 0 ? (
            <PremiumSport
              data={premiumFancy || []}
              handelPremiumFancy={handelPremiumFancy}
            />
          ) : tab == "premiumFancy" && (
            <div className="w-100 bg-white text-black p-2 ">
              No Record Found
            </div>
          )}
           {tab == "declearedFancy" && declearedfancy?.length > 0 ? (
              <div className="account-table batting-table">
              <div className="responsive">
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">Sr No.</th>
                      <th scope="col">Client</th>
                      <th scope="col">Session</th>
                      <th scope="col">Rate </th>
                      <th scope="col">Amount</th>
                      <th scope="col">Runs</th>
                      <th scope="col">Winner</th>
                      <th scope="col">No</th>
                      <th scope="col">Yes</th>
                      <th scope="col">Date & Time</th>
                    </tr>
                  </thead>
                  {declearedfancy && declearedfancy?.length > 0 ? (
                    declearedfancy?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td> {item?.clientName}</td>
                          <td> {item?.sessionBetId}</td>
                          <td> {item?.bhav}</td>
                          <td> {item?.amount}</td>
                          <td> {item?.betRun}</td>
                          <td> {item?.decisionRun}</td>
                          <td> {item?.type == "No" ? "No" : "N/A"}</td>
                          <td> {item?.type == "Yes" ? "Yes" : "N/A"}</td>
                          <td>
                            {" "}
                            {helpers.dateFormat(item.timeInserted)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={12}>
                        <span>You have no bets in this time period.</span>
                      </td>
                    </tr>
                  )}
                </Table>
              </div>
            </div>
          ) : tab == "declearedFancy" && (
            <div className="w-100 bg-white text-black p-2 ">
              No Record Found
            </div>
          )}
        </div>
      </div>
      {bookPositionData?.status && (
        <BookFancy
          setBookPositionData={setBookPositionData}
          data={bookPositionData}
        />
      )}
    </div>
  );
};

export default FancySport;
